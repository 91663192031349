<template>
  <div class="slideshow">
    <div class = "img-wrapper">
      <transition-group tag="div" class="img-slider" name="fade">
        <div v-for="i in [currentIndex]" :key="i" >
          <img :src="currentImg"/>
        </div>
      </transition-group>
    </div>
    <div class="scontrino-wrapper" :class="{ mostraScontrino: carrello && carrello.length > 0}">
      <scontrino-semplificato :righe="carrello" />
    </div>
  </div>
</template>

<script>
import ScontrinoSemplificato from '@/components/scontrinoSlide/ScontrinoSemplificato.vue'
export default {
  components: {
    ScontrinoSemplificato
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      immagini: [],
      imagesCount: 13,
      imageTimer: null,
      imageInterval: 13, // in secondi
      currentIndex: 0
    }
  },
  mounted() {
    for (let i = 1; i <= this.imagesCount; i++) {
      this.immagini.push(`${this.publicPath}img/slide/immagine_${String(i).padStart(2, '0')}.jpg`)
    }
    if (this.immagini.length > 0) {
      this.imageTimer = setInterval(() => {
        this.currentIndex = this.currentIndex + 1
      }, this.imageInterval * 1000)
    }
  },
  computed: {
    carrello() {
      console.log('carrello:', this.$store.getters['scontrino/carrello'])
      return this.$store.getters['scontrino/carrello']
    },
    currentImg: function() {
      return this.immagini[Math.abs(this.currentIndex) % this.immagini.length]
    }
  }
}
</script>

<style lang="scss">
  .slideshow {
    display: block;
    width: 100vw;
    height: 100vh;
    text-align: center;
    box-sizing: border-box;
    padding-top: 20vh;
    overflow: hidden;

    & .img-wrapper {
      position:absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      & img {
        width: 100%;
        height: 100vh;
      }
    }

    & .scontrino {
      height: 100%;
    }

    & .scontrino-wrapper {
      position: absolute;
      top: 5px;
      width: 50vw;
      height: 100vh;
      @media all and (max-width:1240px) {
        width: 80vw;
      }
      transition: all 0.9s ease;
      opacity: 0;
      &.mostraScontrino {
        opacity: 1;
      }
    }

    & .carrello {
      height: 96vh;
      opacity: 0.85;
      & .descrizione {
        text-align: left;
      }
    }
  }

.fade-enter-active,
.fade-leave-active {
  transition: all 10s ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width:100%;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width:100%;
  opacity: 0;
}

</style>
