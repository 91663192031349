<template>
  <div class="scontrino">
    <carrello
      :righe="righe"
    />
<!--       :reso="context.resoInCorso"
      :scontrinoTick="context.scontrinoTick" -->
  </div>
</template>

<script>
import Carrello from '@/components/scontrinoSlide/Carrello.vue'

export default {
  components: {
    Carrello
  },
  props: {
    righe: Array
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
</style>
