<template>
  <div class="carrello" :class="{ reso: isReso }" ref="scontrino" >
    <riga-carrello v-for="(riga, index) in righeScontrino"
      :key="index"
      :riga="riga"
      :reso="reso"
    />
  </div>
</template>

<script>
import RigaCarrello from '@/components/scontrinoSlide/RigaCarrello.vue'

export default {
  props: {
    righe: Array,
    reso: Boolean
  },
  components: {
    RigaCarrello
  },
  data() {
    return {
      righeScontrino: []
    }
  },
  mounted() {
    this.calcRigheScontrino()
  },
  computed: {
    isReso() {
      return this.reso
    }
  },
  watch: {
    righe(value) {
      this.calcRigheScontrino()
      this.$nextTick(() => {
        this.$refs.scontrino.scrollTop = this.$refs.scontrino.scrollHeight
      })
    },
    scontrinoTick() {
      this.calcRigheScontrino()
    }
  },
  methods: {
    calcRigheScontrino() {
      const righe = []
      for (var riga of this.righe) {
        righe.push(riga)
        riga.sconto && righe.push(riga.sconto)
        riga.abbuono && righe.push(riga.abbuono)
      }
      this.righeScontrino = [...righe]
    }
  }
}
</script>

<style scoped lang="scss">
  .tipoDocumento {
    width:100%;
    text-align: center;
    font-size: 2em;
  }

  .reso {
    color: #ff0000;
  }

  .recupero {
    color: #0000ff;
  }
</style>
