<template>
  <div>
    <template v-if="isSeparatore">
      <hr/>
    </template>
    <div v-else
      class="wrapper" :class="{reso: isReso}"
    >
      <div class="quantita-reso"><span v-if="riga.quantitaReso">{{-riga.quantitaReso}}</span></div>
      <div class="quantita"><span v-if="riga.quantita">{{quantita}}</span></div>
      <div class="unitario"><span v-if="riga.prezzoUnitario">{{riga.prezzoUnitario}}</span></div>
      <div class="descrizione" :class="{ bold: isTotale, riepilogo: isRiepilogo }">{{riga.descrizione}}<div class="nota" v-if="riga.nota">{{riga.nota}}</div></div>
      <div class="iva"><span v-if="riga.aliquota">{{riga.aliquota + '%'}}</span></div>
      <div class="prezzo" :class="{ bold: isTotale, riepilogo: isRiepilogo }"><span>{{riga.prezzo}}</span></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    riga: Object,
    reso: Boolean
  },
  data() {
    return {
      isSelected: false
    }
  },
  computed: {
    isSeparatore() {
      return this.riga.tipo === 'separatore'
    },
    isTotale() {
      return ['totale', 'resto', 'totalecomplessivo'].includes(this.riga.tipo)
    },
    isRiepilogo() {
      return [
        'contanti',
        'cartadicredito',
        'buonipasto',
        'nonriscosso',
        'nonriscossobeni',
        'nonriscossoservizi',
        'scontoapagare',
        'buonimultiuso',
        'buonimonouso',
        'fidelitycard',
        'descrizione'
      ].includes(this.riga.tipo)
    },
    isReso() {
      return this.riga.quantitaReso || this.riga.quantita < 0
    },
    quantita() {
      let qta = this.riga.quantita
      if (this.riga.quantitaReso) {
        // qta = `-${this.riga.quantitaReso} .. ${this.riga.quantita}`
        qta = ` ... ${this.riga.quantita}`
      }
      return qta
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
  .wrapper {
    display: grid;
    /* grid-template-columns: 9% 6% 56% 5% 18%; */
    grid-template-columns: 3% 6% 6% 56% 5% 18%;
    grid-column-gap: 5px;
    grid-row-gap: 2px;
    grid-auto-rows: minmax(24px, auto);

    &.selected {
      background-color: #00000033;
    }

    &.reso div {
      color: #ff0000;
    }

    & div {
      &.quantita,
/*       &.quantita-reso, */
      &.unitario,
      &.prezzo {
        width:100%;
        text-align: right;
      }

      &.quantita span {
        padding-right: 10px;
      }

/*       &.quantita span.reso-di {
        font-size:1.1em;
        text-transform: lowercase;
      } */

      &.unitario {
        @media all and (min-width:768px) {
          font-size:0.7em;
        }
        @media all and (min-width:1024px) {
          font-size:0.9em;
        }
        @media all and (min-width:1400px) {
          font-size:1.1em;
        }
        align-self: stretch;
        margin-top: 5px;
        & span {
          padding-right: 2px;
        }
      }

      &.iva {
        font-size:1.4em;
        align-self: stretch;
        margin-top: 5px;
      }

      & .nota {
        font-size:0.8em;
        font-style: italic;
      }

      &.bold {
        font-weight: bolder;
      }

      &.riepilogo {
        font-size:1.4em;
        font-style: italic; /* TODO: Verificare */
      }
    }
  }

</style>
